<template>
  <a-card :bordered="false" class="card-area afterSale">
    <!-- top area -->
    <div class="device-message" hidden>
      <div class="device-message-content">
        <div class="device-message-img">
          <div>故障总数</div>
          <img src="../../../static/img/dataUrlImage_1.png" style="width:32px;height:36px;"/>
        </div>
        <div class="device-message-num" style="color:#FFBF43">{{ deviceTotal.total }}</div>
      </div>
      <div class="device-message-content">
        <div class="device-message-img">
          <div>待处理数</div>
          <img src="../../../static/img/dataUrlImage_0.png" style="width:32px;height:36px;"/>
        </div>
        <div class="device-message-num" style="color:#FF934A">{{ deviceTotal.totalUnhandled }}</div>
      </div>
      <div class="device-message-content">
        <div class="device-message-img">
          <div>今日新增故障</div>
          <img src="../../../static/img/dataUrlImage_2.png" style="width:32px;height:36px;"/>
        </div>
        <div class="device-message-num" style="color:#41A5F5">{{ deviceTotal.totalToday }}</div>
      </div>
      <div class="device-message-content">
        <div class="device-message-img">
          <div>近7天新增故障</div>
          <img src="../../../static/img/dataUrlImage_3.png" style="width:32px;height:36px;"/>
        </div>
        <div class="device-message-num" style="color:#29CDBC">{{ deviceTotal.totalWeek }}</div>
      </div>
    </div>

    <l-search
      placeholder="搜索联系人或电话"
      @search="search"
      @reset="reset"></l-search>

    <div>
      <!-- table area -->
      <a-table :columns="columns"
               :dataSource="dataSource"
               :pagination="pagination"
               :loading="loading"
               :rowKey="record => record.id"
               @change="handleTableChange">
        <template slot="status" slot-scope="text, record">
          <a-tag v-if="record.state==0" color="green">待处理</a-tag>
          <a-tag v-else color="cyan">已处理</a-tag>
        </template>
        <template slot="action" slot-scope="text, record">
          <action-view @click="view(record)"></action-view>
          <action-delete @click="view(record)"></action-delete>
        </template>
      </a-table>
    </div>
  </a-card>
</template>
<script>
import table from '@/utils/table'
import LSearch from '../../components/widget/Search.vue'

export default {
  components: {LSearch},
  name: 'AfterSale',
  data () {
    return {
      advanced: false,
      keyword: '',
      deviceTotal: {
        total: 0,
        totalUnhandled: 0,
        totalToday: 0,
        totalWeek: 0
      },
      status: [
        {
          value: 0,
          label: '所有状态'
        },
        {
          value: 1,
          label: '在线'
        },
        {
          value: 3,
          label: '离线'
        }
      ],
      dataSource: [],
      pagination: table.pagination(),
      loading: false
    }
  },
  created () {

    this.getList()

    this.$get('ticket/overview', {}).then((r) => {
      console.log(r.data)
      let data = r.data
      this.deviceTotal = data
    })
  },
  computed: {
    columns () {
      return [
        {
          title: '设备地址',
          dataIndex: 'id'
        },
        {
          title: '故障时间',
          dataIndex: 'username'
        },
        {
          title: 'IMEI',
          dataIndex: 'contact'
        },
        {
          title: '故障信息',
          dataIndex: 'description'
        },
        {
          title: '状态',
          dataIndex: 'state',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '处理',
          key: 'action',
          scopedSlots: {customRender: 'action'}
        },
        {
          title: '',
          key: 'xxx',
          scopedSlots: {customRender: 'action'}
        }
      ]
    }
  },
  methods: {
    handleMenuClick (e) {
      console.log(e)
    },
    view (record) {
      console.log(record)
    },
    search (keyword) {
      this.keyword = keyword
      this.getList()
    },
    reset () {
      this.keyword = ''
      this.getList()
    },
    handleTableChange (pagination, filters, sorter) {
      // 将这三个参数赋值给Vue data，用于后续使用
      this.pagination = pagination
      this.getList()
    },
    getList () {
      this.loading = true
      var paras = {
        keyword: this.keyword,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      this.$get('ticket', paras).then((r) => {
        let data = r.data
        this.dataSource = data.rows

        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.pagination = pagination

        this.loading = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
  @import "../../../static/less/Common";

  .afterSale {
  }

  .device-message {
    height: 92px;
    width: 100%;
    position: relative;
    margin-bottom: 12px;
  }

  .device-message-content {
    height: 92px;
    width: 23.889%;
    box-shadow: 0 0 1px 0px rgb(206, 206, 206);
    float: left;
    margin-left: 1.48%;
    background-color: #fff;
    position: relative;
  }

  .device-message-content:first-child {
    margin-left: 0px;
  }

  .device-message-num {
    float: right;
    font-size: 28px;
    margin-right: 16px;
    line-height: 92px;
  }

  .device-message-img {
    float: left;
    font-size: 12px;
    color: #333;
    height: 72px;
    width: 105px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    margin-left: 24px;
  }

  .device-message-img > div {
    margin-bottom: 14px;
  }
</style>
